import { isNullOrUndefined, notNullNotUndefined } from "utils/objectUtils"
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from "@bugsnag/plugin-react"
import { getAuthenticatedUser, getLastAuthenticatedUser } from "modules/react-auth/currentUser"
import { blurString } from "utils/stringUtils"
import { getWindowLocationHrefSafe } from "utils/windowUtils"
import { nowEpochSeconds } from "utils/dateUtils"
import md5 from 'md5'


const appendAxiosErrorMetadata = (event, error) => {
    if (error.config?.url) {
        event.request.url = error.config?.url
    }
}

export const init = ({apiKey, environment}) => {
    notNullNotUndefined(apiKey)
    notNullNotUndefined(environment)

    if (Bugsnag._client) {
        //already initialized
        return;
    }

    Bugsnag.start({
        apiKey,
        autoTrackSessions: false,
        releaseStage: environment,
        plugins: [new BugsnagPluginReact()],
        maxEvents: 30,
        onError: function (event) {
            try {
                // User
                const user = getAuthenticatedUser()
                if (user?.me.accessContext.account) {
                    const userId = user.me.accessContext.account.userId
                    const name = user.me.accessContext.account.name
                    let nameSuffix = '';
                    if (user.me.accessContext.isUsingYoioToken) {
                        nameSuffix += ' (YT actor: ' + user.me.accessContext.identity.name + ')'
                    }
                    event.setUser(userId, null, blurString(name) + nameSuffix)
                } else if (user?.me.accessContext.guestIdentity) {
                    const userId = user.me.accessContext.guestIdentity.guestId
                    const name = user.me.accessContext.guestIdentity.name
                    event.setUser(userId, null, blurString(name))
                }

                const metaDataUser = {}

                if (user?.me.accessContext?.credential?.exp) {
                    metaDataUser.exp = user.me.accessContext.credential.exp
                    metaDataUser.expired = nowEpochSeconds() > user.me.accessContext.credential.exp
                }

                const lastAuthenticatedUser = getLastAuthenticatedUser()
                if (lastAuthenticatedUser) {
                    metaDataUser.lastUser = lastAuthenticatedUser.userId
                } else {
                    metaDataUser.lastUser = '(none)'
                }

                event.addMetadata("user", metaDataUser)

                const medaDataWindow = {}

                // Window
                const href = getWindowLocationHrefSafe()
                if (href) {
                    medaDataWindow.path = href
                }

                try {
                    medaDataWindow.navigatorOnLine = navigator.onLine
                } catch (error) {
                    console.log(error)
                }

                try {
                    medaDataWindow.yoioInfo = window?.yoioInfo || {}
                    if (isNullOrUndefined(medaDataWindow.yoioInfo.clientSideStale)) {
                        medaDataWindow.yoioInfo.clientSideStale = null
                    }
                } catch (error) {
                    console.log(error)
                }

                if (Object.keys(medaDataWindow).length > 0) {
                    event.addMetadata("window", medaDataWindow)
                }

                if (event.originalError?.config?.url) { // If is Axios
                    appendAxiosErrorMetadata(event, event.originalError)
                }

                let groupingByContext = event.context ? ('_' + md5(event.context)) : ''
                event.groupingHash = (event.groupingHash || event.errors[0]?.errorClass) + groupingByContext + '_'+ environment + '_' + (event.severity||'') + '_' + event.unhandled

                const errorMessage = event.errors[0]?.errorMessage
                if (errorMessage?.includes('failed with status')) {
                    event.groupingHash += '_' + errorMessage
                }
            } catch (error) {
                console.error(error)
            }

        }
    })
}