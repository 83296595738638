import NextErrorView from 'next/error'
import Bugsnag from '@bugsnag/js';
import React from 'react';
import { init } from 'modules/yoio/errorsBugsnagInit';

let ErrorBoundary = null

export const BugsnagWrapper = ({environment, apiKey, children}) => {

    if (ErrorBoundary == null) {
        console.log('bs init')
        init({apiKey, environment})
        ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
    }

    return (
        //@ts-ignore
        <ErrorBoundary FallbackComponent={<NextErrorView statusCode={500}/>}>
            {children}
        </ErrorBoundary>
    )

}